export default {
  namespaced: true,
  state: {
    menu: [
      {
        name: "Inicio",
        url: "/",
        icon: "mdi-home",
        permissions: ["HOME"],
      },

      {
        name: "Tracking",
        url: "/tracking_carga",
        icon: "mdi-format-list-checks",
        permissions: [4111],
      },

      {
        config: true,
        name: "Coordinación",
        icon: "mdi-calendar-blank-multiple",
        permissions: [1, 13, 33, 32, 31, 14, 20, 19],
        dropDown: [
          {
            name: "Inventario de guias",
            url: "/inventario_guias",
            icon: "mdi-format-list-checks",
            permissions: [1],
          },
          {
            name: "Guias Coordinacion",
            url: "/lista_mawb",
            icon: "mdi-format-list-checks",
            permissions: [13],
          },

          {
            name: "Coordinacion",
            url: "/hoja_coordinacion",
            icon: "mdi-calendar-range",
            permissions: [33],
          },
          {
            name: "Cambio guia de Coordinacion",
            url: "/cambio_guia_coord",
            icon: "mdi-file-arrow-left-right",
            permissions: [32],
          },
          {
            name: "Cambiar carga de guia",
            url: "/cambio_hawb_awb",
            icon: "mdi-swap-horizontal",
            permissions: [31],
          },
          {
            name: "Administrar DAES",
            url: "/lista_daes_gen",
            icon: "mdi-file-clock",
            permissions: [14],
          },

          {
            name: "Coordinacion y reservas",
            url: "/coordinar_guias",
            icon: "mdi-airplane-edit",
            permissions: [20],
          },
          {
            name: "Confirmar Reservas",
            url: "/confirmar_reservas",
            icon: "mdi-airplane-check",
            permissions: [19],
          },
        ],
      },
      {
        name: "Guias Aéreas",
        url: "/lista_guias",
        icon: "mdi-airplane-search",
        permissions: [13],
      },

      {
        name: "Asignar transportista",
        url: "/asignar_transp",
        icon: "mdi-truck-check-outline",
        permissions: [53],
      },

      {
        name: "Dimensiones cajas",
        url: "/ingreso_dimensiones",
        icon: "mdi-move-resize",
        permissions: [60],
      },

      {
        name: "Transacciones bodega",
        url: "/carga_bodega",
        icon: "mdi-package-variant-closed",
        permissions: [57],
      },
      {
        name: "PODs",
        url: "/pod_lista",
        icon: "mdi-package-variant-closed-check",
        permissions: [59],
      },
      {
        name: "Resumen transportista",
        url: "/resumen_transportista",
        icon: "mdi-truck-check-outline",
        permissions: [59],
      },
      {
        name: "Guias Master locales",
        url: "/guias_madres_mia",
        icon: "mdi-truck-check-outline",
        permissions: [61],
      },
      {
        name: "Guias Hijas locales",
        url: "/guias_hijas_mia",
        icon: "mdi-truck-check-outline",
        permissions: [62],
      },
      //para fincas
      {
        name: "Administrar DAES ",
        url: "/lista_daes",
        icon: "mdi-file-clock",
        permissions: [5],
      },
      {
        name: "Mis reservas",
        url: "/mis_reservas_coord",
        icon: "mdi-format-list-checks",
        permissions: [3111],
      },
      {
        name: "Coordinacion y reservas",
        url: "/reservar_guias",
        icon: "mdi-airplane-edit",
        permissions: [2],
      },

      {
        name: "Reservas ",
        url: "/reservas_consolidado",
        icon: "mdi-airplane-edit",
        permissions: [18],
      },

      {
        name: "Historial de reservas",
        url: "/historial_reservas",
        icon: "mdi-airplane-clock",
        permissions: [10],
      },
      {
        name: "Descargar guias hijas",
        url: "/historial_embarques",
        icon: "mdi-file-download",
        permissions: [10],
      },
      ///
      {
        config: true,
        name: "Administracion",
        icon: "mdi-account-cog",
        permissions: [11, 12, 13, 15, 34, 35, 36, 37, 38, 39, 54, 55, 58],
        dropDown: [
          {
            name: "Agentes de carga",
            url: "/agente_carga",
            icon: "mdi-web",
            permissions: [11],
          },
          {
            name: "Shippers",
            url: "/shipper",
            icon: "mdi-ship-wheel",
            permissions: [35],
          },
          {
            name: "Notifies",
            url: "/notify",
            icon: "mdi-account-alert",
            permissions: [36],
          },
          {
            name: "Aerolineas",
            url: "/aerolineas",
            icon: "mdi-airplane",
            permissions: [34],
          },
          {
            name: "Clientes",
            url: "/clientes",
            icon: "mdi-domain",
            permissions: [37],
          },
          {
            name: "Fincas",
            url: "/fincas",
            icon: "mdi-flower",
            permissions: [12],
          },

          {
            name: "Transportistas",
            url: "/transportistas",
            icon: "mdi-truck",
            permissions: [54],
          },

          {
            name: "Paises",
            url: "/paises",
            icon: "mdi-map-marker",
            permissions: [39],
          },
          {
            name: "Aeropuertos",
            url: "/aeropuertos",
            icon: "mdi-airport",
            permissions: [38],
          },

          {
            name: "Contactos",
            url: "/contactos",
            icon: "mdi-account",
            permissions: [55],
          },
        ],
      },

      {
        config: true,
        name: "Seguridad",
        icon: "mdi-shield",
        permissions: [15, 16],
        dropDown: [
          {
            name: "Usuarios",
            url: "/usuarios",
            icon: "mdi-account-multiple",
            permissions: [15],
          },

          {
            name: "Accesos Fincas",
            url: "/usuarios_fincas",
            icon: "mdi-account-key",
            permissions: [16],
          },
          {
            name: "Permisos",
            url: "/permisos",
            icon: "mdi-account-key",
            permissions: [16],
          },
          {
            name: "Privilegios",
            url: "/privilegios",
            icon: "mdi-account-key",
            permissions: [16],
          },
        ],
      },
    ],
  },
};
