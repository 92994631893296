import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import VueSweetalert2 from "vue-sweetalert2";
import { VueMaskDirective } from "v-mask";
Vue.directive("mask", VueMaskDirective);
import { TiptapVuetifyPlugin } from "tiptap-vuetify";
//import EasyCamera from 'easy-vue-camera';
import es from "vuetify/src/locale/es.ts";
import en from "vuetify/src/locale/en.ts";
import "sweetalert2/dist/sweetalert2.min.css";

//Vue.use(EasyCamera);
Vue.use(VueSweetalert2);
Vue.config.productionTip = false;
Vue.use(require("vue-moment"));
Vue.use(TiptapVuetifyPlugin, {
  vuetify,
  iconsGroup: "fa",
});
Vue.use(vuetify, {
  lang: {
    locales: { es, en },
    current: "es",
    locale: "es",
  },
});
new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
