import Vue from 'vue'
import Vuex from 'vuex'
import master from './modules/master'
import access from './modules/access'


Vue.use(Vuex)

export default new Vuex.Store({
	modules: {
		master,
		access,	 
		
	}
})
