<template>
  <v-app>
    <v-navigation-drawer
      app
      id="core-navigation-drawer"
      v-model="drawer"
      dark
      mobile-breakpoint="640"
      width="230"
      v-if="getIsLoggedIn"
    >
      <template v-slot:img="props">
        <v-img :gradient="`to bottom`" v-bind="props" />
      </template>
      <v-divider class="mb-1" />
      <v-list dense nav>
        <v-list-item>
          <v-list-item-avatar class="align-self-center" color="white" contain>
            <v-icon color="#000">mdi-account-convert</v-icon>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title class="py-2 text-uppercase">{{
              user.name == null ? user.user_login_id : user.name
            }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-divider class="mb-2"></v-divider>
      <v-list expand nav dense :key="`list-${tenantId}`">
        <v-subheader>Menú</v-subheader>
        <v-list-item
          v-for="(m, x) in menu"
          :key="`menu-${x}`"
          link
          :to="m.url"
          v-if="!!idActionsRol.find((e) => m.permissions.includes(e))"
        >
          <v-list-group no-action link v-if="m.config" class="menu-drop-down">
            <template v-slot:activator>
              <v-icon class="mr-2">{{ m.icon }}</v-icon> {{ m.name }}
            </template>
            <v-list-item
              dense
              sub-group
              class="pl-1"
              v-for="(dd, y) in m.dropDown"
              :key="y"
              :to="dd.url"
              v-if="!!idActionsRol.find((e) => dd.permissions.includes(e))"
            >
              <v-list-item-icon v-if="m.config" class="mr-2">
                <v-icon>{{ dd.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content v-if="m.config">
                <v-list-item-title no-action>{{ dd.name }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
          <v-list-item-icon v-if="!m.config" class="mr-2">
            <v-icon>{{ m.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content v-if="!m.config">
            <v-list-item-title no-action>{{ m.name }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-main>
      <v-toolbar v-if="getIsLoggedIn" dense>
        <v-app-bar-nav-icon @click="setDrawer(!drawer)"></v-app-bar-nav-icon>
        <v-toolbar-title>{{ this.titleToolbar }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <!-- <v-btn small icon class="mr-4"
          ><v-icon>mdi-printer</v-icon>{{ impresoraEtiquetas }}
        </v-btn> -->
        <v-menu
          v-if="dimensionesCajas"
          v-model="dropdownImpresoras"
          :close-on-content-click="false"
          :nudge-width="200"
          offset-y
          transition="slide-y-transition"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="mx-8"
              color="blue-grey darken-1"
              dark
              v-bind="attrs"
              v-on="on"
              icon
            >
              <v-icon left>mdi-printer</v-icon>
              {{ impresoraEtiquetas }}
            </v-btn>
          </template>
          <v-card width="320px">
            <v-list>
              <v-list-item v-for="imp in impresoras" key="PROC_ID">
                <!-- <v-list-item-avatar>
                  <v-icon>mdi-48px mdi-account-circle</v-icon>
                </v-list-item-avatar> -->

                <v-list-item-content>
                  <v-list-item-title>
                    <v-btn @click="setImpresoraUsuario(imp.PROC_ID)">
                      <v-icon small left>mdi-printer</v-icon>
                      {{ imp.DESCRIPCION }}
                    </v-btn>
                  </v-list-item-title>
                  <v-list-item-subtitle class="ml-8"
                    >{{ imp.IP_IMPRESORA }}
                  </v-list-item-subtitle>
                </v-list-item-content>
                <!-- <v-list-item-action>
                  <v-btn icon @click="logout">
                    <v-icon>mdi-import</v-icon>
                  </v-btn>
                </v-list-item-action> -->
              </v-list-item>
            </v-list>
          </v-card>
        </v-menu>

        <v-icon left class="ml-4">mdi-domain</v-icon> {{ empresaUsuario }}
        <v-menu
          v-model="dropdownMenu"
          :close-on-content-click="false"
          :nudge-width="200"
          offset-y
          transition="slide-y-transition"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="blue-grey darken-1"
              dark
              v-bind="attrs"
              v-on="on"
              icon
            >
              <v-icon>mdi-account-circle</v-icon>
            </v-btn>
          </template>
          <v-card width="360px">
            <v-list>
              <v-list-item>
                <v-list-item-avatar>
                  <v-icon>mdi-48px mdi-account-circle</v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>{{
                    user.name == null ? user.user_login_id : user.name
                  }}</v-list-item-title>
                  <v-list-item-subtitle
                    >Permiso prinicipal</v-list-item-subtitle
                  >
                </v-list-item-content>
                <v-list-item-action>
                  <v-btn icon @click="logout">
                    <v-icon>mdi-import</v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
            </v-list>
          </v-card>
          <!-- <v-divider></v-divider>
            <v-col cols="12">
              <v-select
                v-model="tenantIdSelected"
                :items="companies"
                item-text="name"
                item-value="tenantId"
                label="Seleccione una empresa"
                outlined
                dense
                @change="setSelectedCompany"
              ></v-select>
            </v-col>
            <v-row class="text-center" v-if="modules.length">
              <v-col cols="6" md="4" v-if="pos" @click="setDrawer(true)">
                <v-btn height="60px" to="/posinicio">
                  <v-img src="./assets/images/pos.png" width="50px"> </v-img>
                </v-btn>
                <div class="title-icon-application mt-1">P.O.S</div>
              </v-col>

              <v-col
                cols="6"
                md="4"
                v-if="contabilidad"
                @click="setDrawer(true)"
              >
                <v-btn height="60px" to="/contabilidad">
                  <v-img src="./assets/images/nomina.png" width="50px"> </v-img>
                </v-btn>
                <div class="title-icon-application mt-1">CONTABILIDAD</div>
              </v-col>
              <v-col cols="6" md="4" v-if="store" @click="setDrawer(true)">
                <v-btn height="60px" to="/bodega">
                  <v-img src="./assets/images/bodega.png" width="50px"></v-img>
                </v-btn>
                <div class="title-icon-application mt-1">BODEGA</div>
              </v-col>
              <v-col cols="6" md="4" v-if="purchases" @click="setDrawer(true)">
                <v-btn height="60px" to="/compras">
                  <v-img src="./assets/images/compras.png" width="50px">
                  </v-img>
                </v-btn>
                <div class="title-icon-application mt-1">COMPRAS</div>
              </v-col>
              <v-col cols="6" md="4" v-if="admin" @click="setDrawer(true)">
                <v-btn height="60px" to="/administracion">
                  <v-img src="./assets/images/administrador.png" width="50px">
                  </v-img>
                </v-btn>
                <div class="title-icon-application mt-1">ADMINISTRACIÓN</div>
              </v-col>
            </v-row>
            <v-row v-else>
              <v-col class="mx-2">
                <v-alert type="error" dense>
                  Sin aplicaciones asignadas
                </v-alert>
              </v-col>
            </v-row>
            
          </v-card>
          -->
        </v-menu>
      </v-toolbar>
      <router-view class="mt-2"></router-view>
    </v-main>
    <v-footer padless color="grey lighten-2">
      <v-col class="text-center" cols="12">
        {{ new Date().getFullYear() }} — <strong>{{ company }}</strong>
      </v-col>
    </v-footer>
  </v-app>
</template>

<script>
import { mapState, mapActions, mapMutations, mapGetters } from "vuex";

export default {
  name: "App",
  data: () => ({
    //tenantIdSelected: null,
    dropdownMenu: false,
    dropdownImpresoras: false,
    impresoras: [],
    impresoraEtiquetas: "",
  }),
  computed: {
    company() {
      // var c = this.companies.find((e) => e.tenantId == this.tenantId);
      return "Flowercargo";
    },

    empresaUsuario() {
      if (this.user.tipo_usuario == 1) {
        return this.user.empresa;
      }
      if (this.user.tipo_usuario == 4) {
        return "Flowercargo";
      }
    },

    ...mapGetters("access", [
      "getIsLoggedIn",
      "store",
      "purchases",
      "users",
      "admin",
      "dimensionesCajas",
    ]),

    ...mapState("access", ["modules", "idActionsRol"]),

    ...mapState("master", [
      "companies",
      "user",
      "token",
      "overlay",
      "tenantId",
      "drawer",
      "menu",
      "titleToolbar",
      "impresoraId",
    ]),

    drawer: {
      get: function () {
        return this.$store.state.master.drawer;
      },
      set: function () {
        return this.$store.state.master.drawer;
      },
    },
  },
  methods: {
    ...mapActions("master", ["requestApi", "alertNotification", "setDrawer"]),
    ...mapActions("access", ["errorRequest", "getModules"]),
    ...mapMutations("master", [
      "setUrl",
      "setMenu",
      "setDrawer",
      "setImpresora",
    ]),
    ...mapMutations("access", [
      "setLogedIn",
      "setActionsRol",
      "setAvailableModules",
    ]),

    /*setSelectedCompany() {
      localStorage.setItem("tenantId", this.tenantIdSelected);
      this.setTenantId(this.tenantIdSelected);
      this.getModules();
    },*/

    setImpresoraUsuario(impId) {
      this.setUrl("api/set-impresora-usuario");
      this.requestApi({
        method: "post",
        data: {
          IMP_ID: impId,
          user_name: this.user.user_login_id,
        },
      })
        .then((res) => {
          this.cargarImpresoras();
          this.dropdownImpresoras = false;
          this.alertNotification({
            param: { html: "Impresora actualizada " },
          });
        })
        .catch((err) => {
          //console.log(err);
        });
    },

    cargarImpresoras() {
      this.setUrl("api/impresoras");
      this.requestApi({
        method: "post",
        data: {
          token: this.token,
          user_name: this.user.user_login_id,
        },
      })
        .then((res) => {
          //localStorage.setItem("impresoraId", 1);
          this.impresoras = res.data.impresoras;
          if (res.data.impresora_usuario.length > 0) {
            this.impresoraEtiquetas = res.data.impresora_usuario[0].DESCRIPCION;
            this.setImpresora(res.data.impresora_usuario[0].PROC_ID);
          }
          //localStorage.removeItem("token");
          //localStorage.setItem("tenantId", null);
          //localStorage.setItem("user", "");
          //this.setTenantId(null);
        })
        .catch((err) => {
          //console.log(err);
        });
    },
    logout() {
      this.setUrl("api/logout");
      this.requestApi({
        method: "post",
        data: {
          token: this.token,
          user_name: this.user.user_login_id,
          //dataBase: this.tenantId,
          system: "sistema_de_carga",
          comment: "ha cerrado sesión ",
        },
      })
        .then((res) => {
          localStorage.removeItem("token");
          //localStorage.setItem("tenantId", null);
          localStorage.setItem("user", "");
          this.setMenu([]);
          //this.setTenantId(null);
          this.setLogedIn(false);
          this.setActionsRol();
          this.alertNotification({
            param: { html: "Adios " + this.user.name },
          });
          if (!this.loggedIn) this.$router.push("/login");
        })
        .catch((err) => {
          console.log(err);
          let response = err.response;
          this.errorRequest({
            data: {
              datos: response.data.errors,
              status: response.status,
            },
          });
        });
    },
  },
  beforeUpdate() {
    //this.tenantIdSelected = this.tenantId;
    this.setMenu(JSON.parse(localStorage.getItem("menu")));
  },
  mounted() {
    document.title = "Sistema de gestión de carga";
    this.cargarImpresoras();
  },
};
</script>

<style>
.title-icon-application {
  font-size: 11px !important;
}

td,
.v-input--selection-controls .v-radio > .v-label {
  font-size: 0.8rem !important;
}

td.custom-price {
  font-size: 1rem !important;
  font-weight: 600;
}

td {
  height: 25px !important;
}

td.td-custom {
  background: #e2e2e2;
  width: 176px;
}

td.td-head-custom {
  background: #8f8f8f;
  color: #fff;
  font-size: 1rem !important;
}

.input-quotation {
  position: relative;
  bottom: 4px;
  border-bottom: 0;
}

input {
  font-size: 14px;
}

.v-text-field input {
  padding-left: 3px;
}

.v-select.v-input--dense .v-select__selection--comma {
  margin: 8px 4px 0px 0 !important;
}

.theme--light.v-select .v-select__selection--disabled,
.v-select__selection--comma,
.v-select:not(.v-text-field--single-line):not(.v-text-field--outlined)
  .v-select__slot
  > input {
  font-size: 13px !important;
  color: #333 !important;
}

.v-select:not(.v-text-field--single-line):not(.v-text-field--outlined)
  .v-select__slot
  > input,
.v-text-field.v-input--dense:not(.v-text-field--outlined) input {
  padding: 6px 0 0px 0px !important;
  font-size: 13px !important;
  color: #333 !important;
}

.v-list-group {
  width: 100%;
}

.v-list-group__header {
  padding: 0 !important;
}

.menu-drop-down {
  font-size: 12px;
  font-weight: 500;
}

.v-list-group__header__append-icon {
  position: absolute;
  right: 0;
}
</style>
