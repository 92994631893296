import Vue from "vue";
import axios from "axios";
import general from "./general";

export default {
  namespaced: true,
  state: {
    urlApi:
      process.env.VUE_APP_NODE_ENV === "production"
        ? process.env.VUE_APP_API_URL_PROD
        : process.env.VUE_APP_API_URL_DEVEL,
    drawer: true,
    loadingBtn: false,
    dialog: false,
    loadingBtn2: false,
    url: "",
    user: null,
    pass: null,
    overlay: false,
    loadingTable: false,
    token: null,
    alertas: "",
    tenantId: null,
    urlExternal: null,
    titleToolbar: "SISTEMA DE CARGA",
    impresoraId: null,
    menu: [],
    months: [
      { number: "01", name: "Enero" },
      { number: "02", name: "Febrero" },
      { number: "03", name: "Marzo" },
      { number: "04", name: "Abril" },
      { number: "05", name: "Mayo" },
      { number: "06", name: "Junio" },
      { number: "07", name: "Julio" },
      { number: "08", name: "Agosto" },
      { number: "09", name: "Septiembre" },
      { number: "10", name: "Octubre" },
      { number: "11", name: "Noviembre" },
      { number: "12", name: "Diciembre" },
    ],

    sweetAlert: {
      title: "Éxito",
      html: "",
      icon: "success",
      toast: true,
      timer: 5000,
      confirmButtonText:
        '<span class="mdi mdi-close-circle-outline"></span> Cerrar',
      timerProgressBar: true,
      position: "top",
      confirmButtonColor: "#a5dc86",
    },
    paramAlertQuestion: {
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: "Cancelar",
      confirmButtonText: "Aceptar!",
    },
    /*objGeoCoding: {
            provider: new GoogleProvider({
                params: {
                    key: 'AIzaSyAqaUSU9oq2u3S7LstJG7oT_o0_u-BLrFE',
                    language: 'es',
                    region:'ec'
                }
            }),
        },*/
    requiredRule: [(v) => !!v || "El campo es obligatorio"],
  },
  mutations: {
    setDrawer(state, payload) {
      state.drawer = payload;
    },

    setDialog(state) {
      state.dialog = !state.dialog;
    },

    setLoadingBtn(state, payload) {
      state.loadingBtn = payload;
    },

    setLoadingBtn2(state) {
      state.loadingBtn2 = !state.loadingBtn2;
    },

    setSeewtNotification(state, payload) {
      if (typeof payload.timer != "undefined")
        state.sweetAlert.timer = payload.timer;

      if (typeof payload.title != "undefined")
        state.sweetAlert.title = payload.title;

      if (typeof payload.icon != "undefined")
        state.sweetAlert.icon = payload.icon;

      if (typeof payload.toast != "undefined")
        state.sweetAlert.toast = payload.toast;

      if (typeof payload.confirmButtonColor != "undefined")
        state.sweetAlert.confirmButtonColor = payload.confirmButtonColor;

      state.sweetAlert.html = payload.html;
    },

    setLoadingTable(state, payload) {
      state.loadingTable = payload;
    },

    setToken(state, payload) {
      state.token = payload;
    },

    setUser(state, payload) {
      state.user = payload;
    },

    setImpresora(state, payload) {
      state.impresoraId = payload;
    },

    setUrl(state, payload) {
      state.url = state.urlApi + payload;
    },

    setOverlay(state, payload) {
      state.overlay = payload;
    },

    setMenu(state, payload) {
      localStorage.setItem("menu", JSON.stringify(general.state.menu));
      //state.menu = JSON.parse(localStorage.getItem('menu'))
      state.menu = general.state.menu;
    },

    setTitleToolbar(state, payload) {
      state.titleToolbar =
        typeof payload == "undefined" ? "SISTEMA DE CARGA" : payload;
    },
  },
  actions: {
    alertNotification({ commit, state }, payload) {
      commit("setSeewtNotification", {
        html: payload.param.html,
        timer: payload.param.timer,
        title: payload.param.title,
        icon: payload.param.icon,
        toast: payload.param.toast,
        confirmButtonColor: payload.param.confirmButtonColor,
        confirmButtonText: payload.param.confirmButtonText,
      });

      Vue.swal({
        title: state.sweetAlert.title,
        html: state.sweetAlert.html,
        icon: state.sweetAlert.icon,
        toast: state.sweetAlert.toast,
        timerProgressBar: state.sweetAlert.timerProgressBar,
        timer: state.sweetAlert.timer,
        position: state.sweetAlert.position,
        confirmButtonText: state.sweetAlert.confirmButtonText,
        confirmButtonColor: state.sweetAlert.confirmButtonColor,
      });

      commit("setSeewtNotification", {
        title: "Éxito",
        icon: "success",
        toast: true,
        timer: 5000,
        confirmButtonColor: "#a5dc86",
      });
    },

    requestApi({ state }, payload) {
      payload.data.user_name = state.user.user_login_id;
      payload.data.empresa_id = state.user.empresa_id;

      let body = { params: payload.data };

      if (
        (payload.method === "PATCH" || payload.method === "PUT") &&
        state.url.split("/").length < 5
      ) {
        body = { data: { data: payload.data } };
      } else if (
        (payload.method === "POST" ||
          payload.method === "DELETE" ||
          payload.method === "PATCH" ||
          payload.method === "PUT") &&
        state.url.split("/").length > 4
      ) {
        body = { data: payload.data };
      } else if (
        payload.method === "POST" ||
        payload.method === "DELETE" ||
        payload.method === "PATCH" ||
        payload.method === "PUT"
      ) {
        body = { data: payload.data };
      }

      return new Promise((resolve, reject) => {
        axios({
          method: payload.method,
          url: state.url,
          ...body,
          headers: {
            Authorization: "Bearer " + state.token,
          },
        })
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
  },
};
